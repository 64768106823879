import React, { useEffect } from "react";
import styles from './index.module.css';
import { useState } from "react";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Commandes from "./commandes.modules";
import jwtDecode from "jwt-decode";
import CommandeDetails from "./commande-details.module";
import StagiairesList from "./stagiaires-lists.module";
import HomeAdmin from "./home.module";
import StagesList from "./stages-list.module";
import StagesEditor from "./stages-editor.module";
import OptionsEditor from "./options-editor.module";
import { CookiesProvider, useCookies } from 'react-cookie'
import { UilCalendarAlt } from '@iconscout/react-unicons'
import { UilUser } from '@iconscout/react-unicons'
import PaiementsToConfirm from "./paiement-to-confirm.module";
import MailExtractor from "./mail-extractor.module";


export const AdminPanelContext = React.createContext("adminpanelcontext");

function MainAdmin(props) {

    const [title, setTitle] = useState("[TITRE]");
    const [login, setLogin] = useState("[LOGIN]");
    const [cookies, setCookie] = useCookies(['year', 'token']);
    const [jwt, setJwt] = useState(cookies.token || null);
    const [idRoute, setIdRoute] = useState(0);

    const [year, setYear] = useState(cookies.year || new Date().getFullYear());

    const navigate = useNavigate();

    const getToken = function () {
        if(!jwt) {
            const j = cookies.token;

            if(!j) {
                navigate('/admin/login');
                return null;
            }
            else {
                setJwt(j);
                return j;
            }
        }
        else {
            return jwt;
        }
    }

    useEffect(() => {
        try {
            const data = jwtDecode(getToken());
            setLogin(data.login);
        }
        catch(err) {

        }
        
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h1>YCBL  -  { title }</h1>

                <span style={{ display: "flex", alignItems: "center", borderBottom: "3px solid white", margin: 5}}><UilCalendarAlt></UilCalendarAlt>
                <select value={year} style={{color: "white", width: "fit-content", fontSize: 20, margin: 0, padding: 5}} onChange={(e) => {
                    setYear(e.target.value);
                    setCookie('year', e.target.value);
                }}>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>

                </select>
                </span>
                <div className={styles.accountBox}>
                    <UilUser></UilUser>
                    <p>{ login }</p>
                </div>
            </div>

            <div className={styles.mainContent}>
                <aside>
                    <nav>
                        <li className={(idRoute == 0) ? styles.selectedRoute : null} onClick={ () => {navigate('/admin/'); setIdRoute(0)} }>Accueil</li>
                        <li className={(idRoute == 1) ? styles.selectedRoute : null} onClick={ () => {navigate('/admin/commandes'); setIdRoute(1)} }>Commandes</li>
                        <li className={(idRoute == 2) ? styles.selectedRoute : null} onClick={ () => {navigate('/admin/reservations'); setIdRoute(2)} }>Réservations - stagiaires</li>
                        <li className={(idRoute == 3) ? styles.selectedRoute : null} onClick={ () => {navigate('/admin/stages'); setIdRoute(3)} }>Stages et options</li>
                        <li className={(idRoute == 4) ? styles.selectedRoute : null} onClick={ () => {navigate('/admin/paiements'); setIdRoute(4)} }>Paiements en attente</li>
                        <li className={(idRoute == 5) ? styles.selectedRoute : null} onClick={ () => {navigate('/admin/mailextract'); setIdRoute(5)} }>Extraction des mails</li>
                    </nav>
                </aside>

                <div className={styles.content}>
                        <AdminPanelContext.Provider value={{ setTitle, getToken, year }}>
                            <Routes>
                                <Route path="/" element={<HomeAdmin></HomeAdmin>}></Route>
                                <Route path="commandes" element={<Commandes></Commandes>}></Route>
                                <Route path="commandes/details/:id" element={<CommandeDetails></CommandeDetails>}></Route>
                                <Route path="reservations" element={<StagiairesList></StagiairesList>}></Route>
                                <Route path="stages" element={<StagesList></StagesList>}></Route>
                                <Route path="stages/editor/:id" element={<StagesEditor></StagesEditor>}></Route>
                                <Route path="options/editor/:id" element={<OptionsEditor></OptionsEditor>}></Route>
                                <Route path="paiements" element={<PaiementsToConfirm></PaiementsToConfirm>}></Route>
                                <Route path="mailextract" element={<MailExtractor></MailExtractor>}></Route>
                            </Routes>
                        </AdminPanelContext.Provider>
                </div>
            </div>
        </div>
    );


}


export default MainAdmin;